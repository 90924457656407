
import { Component, Vue } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { ISelectItem } from "@/types";
import { ICustomer, ICustomerListRequest } from "@/types/customer";
import { IBranch, IBranchListRequest } from "@/types/branch";
import { IReceivedOrder } from "@/types/received_order";
import { IUserListRequest } from "@/types/user";
import { IMaker, IMakerListRequest } from "@/types/maker";
import { IProductListRequest } from "@/types/product";
import { IUser } from "@/types/user";
import { IProduct } from "@/types/product";
import { IStock, IStockListRequest } from "@/types/stock";
import { ProductShipment } from "@/models/product_shipment";
import {
  IShipment,
  IShipmentCreateRequest,
  IShipmentDetail,
  IShipmentListRequest
} from "@/types/shipment";
import { ShipmentDetail, ShipmentRequest } from "@/models/shipment";
import {
  ICustomerProduct,
  ICustomerProductListRequest
} from "@/types/customer_product";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //shipment
  @Action("shipment/adminGetList")
  public getshipments!: (
    shipment_params: IShipmentListRequest
  ) => Promise<boolean>;

  @Action("shipment/adminGet")
  public getShipment!: (shipment_id: number) => Promise<boolean>;

  @Action("shipment/adminPreShipmentCreate")
  public preShipmentCreate!: (
    params: IShipmentCreateRequest
  ) => Promise<boolean>;

  @Action("shipment/adminDelete")
  public deleteShipment!: (shipment_id: number) => Promise<boolean>;

  @Getter("shipment/single")
  public shipment!: IShipment;

  @Getter("shipment/filterdShipmentList")
  public shipmentFilterdSelectItem!: (received_order_id: number) => IShipment[];

  @Mutation("shipment/clear")
  public clear!: () => void;

  //customer
  @Action("customer/adminGetList")
  public getCustomers!: (params: ICustomerListRequest) => Promise<boolean>;

  @Action("customer/adminGet")
  public getCustomer!: (customer_id: number) => Promise<boolean>;

  @Getter("customer/selectItem")
  public customerList!: ISelectItem[];

  @Getter("customer/single")
  public customer!: ICustomer;

  @Getter("customer/find")
  public findCustomer!: (id: number) => ICustomer;

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  //branch
  @Action("branch/adminGetList")
  public getBranches!: (params: IBranchListRequest) => Promise<boolean>;

  @Getter("branch/selectItem")
  public branchList!: ISelectItem[];

  @Getter("branch/find")
  public findBranch!: (id: number) => IBranch;

  @Mutation("branch/clear")
  public clearBranch!: () => void;

  //received_order
  @Action("received_order/adminGet")
  public getReceivedOrder!: (received_order_id: number) => Promise<boolean>;

  @Action("received_order/adminReceivedOrderUpdateFixedDeliveryDate")
  public adminReceivedOrderUpdateFixedDeliveryDate!: (data: {
    received_order_id: number;
    fixed_delivery_date: string;
  }) => Promise<boolean>;

  @Getter("received_order/single")
  public received_order!: IReceivedOrder;

  @Mutation("received_order/clear")
  public clearReceivedOrder!: () => void;

  //maker
  @Action("maker/adminGetList")
  public getMakers!: (params: IMakerListRequest) => Promise<boolean>;

  @Getter("maker/selectItem")
  public makerList!: ISelectItem[];

  @Getter("maker/find")
  public findMaker!: (id: number) => IMaker;

  @Mutation("maker/clear")
  public clearMaker!: () => void;

  //product
  @Action("product/adminGetProductSegments")
  public getProductSegments!: (params: IProductListRequest) => Promise<boolean>;

  @Getter("product/filterdSelectItem")
  public makerFilterdSelectItem!: (maker_id: number) => ISelectItem[];

  @Getter("product/find")
  public findProduct!: (id: number) => IProduct;

  @Getter("product/single")
  public product!: IProduct;

  @Mutation("product/clear")
  public clearProduct!: () => void;

  // customer_product
  @Action("customer_product/adminGetList")
  public getCustomerProductList!: (
    request: ICustomerProductListRequest
  ) => Promise<boolean>;

  @Getter("customer_product/selectItem")
  public customerProductList!: ISelectItem[];

  @Getter("customer_product/find")
  public findCustomerProduct!: (id: number) => ICustomerProduct;

  @Mutation("customer_product/clear")
  public customerProductClear!: () => void;

  //stock
  @Action("stock/adminGetStockSegments")
  public getStockSegments!: (request: IStockListRequest) => Promise<boolean>;

  @Getter("stock/single")
  public stock!: IStock;

  @Getter("stock/find")
  public findStock!: (stock_id: number) => IStock;

  @Getter("stock/selectWarehouseWithProducts")
  public WarehouseWithProducts!: (
    product_id: number,
    customer_id: number,
    stock_id?: number
  ) => ISelectItem[];

  @Getter("stock/warehouseFilterdSelectItem")
  public warehouseFilterdSelectItem!: (
    product_id: number,
    customer_id: number,
    warehouse_id: number | null,
    stock_id?: number
  ) => ISelectItem[];

  @Mutation("stock/clear")
  public clearStock!: () => void;

  //user
  @Action("user/adminGetList")
  public getUsers!: (params: IUserListRequest) => Promise<boolean>;

  @Action("user/adminGetStaffList")
  public getStaffs!: (params: IUserListRequest) => Promise<boolean>;

  @Getter("user/selectItem")
  public userList!: ISelectItem[];

  @Getter("user/selectItemStaff")
  public staffList!: ISelectItem[];

  @Getter("user/find")
  public findUser!: (id: number) => IUser;

  @Mutation("user/clear")
  public clearUser!: () => void;

  // note
  @Action("note/adminUpdateNote")
  public updateNote!: (
    notes: [
      {
        id: number | null;
        content: string | null;
      }
    ]
  ) => Promise<boolean>;

  //authUser
  @Getter("auth/me")
  public user!: IUser;

  //パラメータ定義
  public params: IShipmentCreateRequest = new ShipmentRequest();

  //複数Lot用配列
  public shipment_details: IShipmentDetail[][] = [[new ShipmentDetail()]];

  //変数定義
  public shipment_status = Vue.prototype.$shipmentStatus;
  public received_order_id = 0;
  public received_order_no = "";
  public received_order_destination = "";
  public fixed_delivery_date = "";
  public user_id = 0;
  public isWarehouse: boolean[][] = [];
  public shipmentList: IShipment[] = [];
  public max_quantity: number[][] = [];
  public maker_id: number[] = [];
  public unit: string[] = [];
  public productOptions: ISelectItem[] = [];
  public customerProductOptions: ISelectItem[] = [];
  public lotOptions: number[][] = [];
  public warehouseOptions: ISelectItem[] = [];
  public received_order_quantity: number[] = [];
  public remaining_shipments: number[] = [];
  public notes: [
    {
      id: number | null;
      content: string | null;
    }
  ] = [
    {
      id: 0,
      content: null
    }
  ];
  public valid = true;
  public valid_product_shipment = true;
  public lazy = false;
  public submit_dialog = false;
  public disp = false;
  public isSubmit = false;
  public menu = {
    product: false,
    branch: false
  };

  public rules = {
    shipment_date: [(v: string) => !!v || "出荷日は必須です"],
    fixed_delivery_date: [(v: string) => !!v || "回答納品日は必須です"],
    customer: [(v: string) => !!v || "顧客は必須です"],
    received_order: [(v: number) => !!v || "顧客注文番号は必須です"],
    maker: [(v: number) => !!v || "メーカは必須です"],
    product: [(v: number) => !!v || "商品は必須です"],
    remaining_shipment: [
      (v: number) => !v || v >= 0 || "残出荷数量を超えています"
    ],
    sales_staff: [(v: number) => !!v || "テクネ担当者は必須です"],
    user_id: [(v: number) => !!v || "登録者は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.received_order_id = Number(this.$route.params.received_order_id) || 0;
    this.params.shipment_status = this.shipment_status.pre_shipment;
    // this.params.user_id = this.user.id;
    this.params.product_shipments.push(new ProductShipment());
    this.max_quantity.push([]);
    this.lotOptions.push([]);
    this.isWarehouse.push([false]);
    this.clear();
    this.clearStock();
    this.clearUser();

    await Promise.all([
      this.getshipments({ per_page: 0 }),
      this.getCustomers({ per_page: 0 }),
      this.getMakers({ per_page: 0 }),
      this.getUsers({ per_page: 0 }),
      this.getCustomerProductList({ per_page: 0 }),
      this.getReceivedOrder(this.received_order_id)
    ]);

    this.received_order_no = this.received_order.order_no;
    this.received_order_destination = this.received_order.destination || "ー";
    this.fixed_delivery_date = this.received_order.fixed_delivery_date || "";
    this.params.received_order_id = this.received_order.id || 0;
    this.params.customer_id = this.received_order.customer_id || 0;
    await this.getCustomer(this.params.customer_id);

    if (this.received_order.branch_id) {
      this.params.branch_id = this.received_order.branch_id || null;
      await Promise.all([
        this.getBranches({
          customer_id: this.params.customer_id,
          per_page: 0
        }),
        this.getStaffs({ branch_id: this.received_order.branch_id })
      ]);
      if (this.staffList.length == 0) {
        await this.getStaffs({ customer_id: this.params.customer_id });
      }
      if (this.staffList.length == 1) {
        this.params.sales_staff = Number(this.staffList[0].value);
      }
    }

    if (!this.received_order.branch_id) {
      await this.getStaffs({ customer_id: this.params.customer_id });
      if (this.staffList.length == 1)
        this.params.sales_staff = Number(this.staffList[0].value);
    }

    await this.setCreateDefault();

    let i = 0;
    for (let [, remaining_shipment] of this.remaining_shipments.entries()) {
      if (remaining_shipment == 0) {
        this.max_quantity.splice(i, 1);
        this.maker_id.splice(i, 1);
        this.unit.splice(i, 1);
        this.productOptions.splice(i, 1);
        this.customerProductOptions.splice(i, 1);
        this.lotOptions.splice(i, 1);
        this.warehouseOptions.splice(i, 1);
        this.isWarehouse.splice(i, 1);
        this.received_order_quantity.splice(i, 1);
        this.shipment_details.splice(i, 1);
        this.params.product_shipments.splice(i, 1);
      } else {
        i += 1;
      }
    }
    this.remaining_shipments = this.remaining_shipments.filter(
      remaining_shipment => remaining_shipment != 0
    );

    this.disp = true;
    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
      (
        this.$refs.product_shipments as Vue & { validate: () => boolean }
      ).validate();
    });
  }

  //--------
  // 新規時デフォルト値をセットする
  private async setCreateDefault() {
    for (let [
      index,
      product_received_order
    ] of this.received_order.products.entries()) {
      if (index > 0) {
        this.appendProductShipment();
        this.shipment_details.push([new ShipmentDetail()]);
        this.max_quantity.push([]);
        this.lotOptions.push([]);
        this.isWarehouse.push([false]);
      }

      const product_shipment = this.params.product_shipments[index];
      this.maker_id[index] = product_received_order.maker_id;
      await this.makerSelected(index);
      product_shipment.product_id = product_received_order.product_id;
      await this.productSelected(index);
      this.received_order_quantity[index] =
        product_received_order.quantity || 0;
      await this.RemainingShipments(index);
      product_shipment.selling_unit_price =
        Number(Number(product_received_order.selling_unit_price).toFixed(2)) ||
        0;
      this.$set(this.customerProductOptions, index, this.customerProductList);
      product_shipment.customer_product_id =
        product_received_order.customer_product_id;
      product_shipment.note_id = product_received_order.note_id;
      if (index == 0) {
        this.notes[0].id = product_received_order.note_id;
        this.notes[0].content = product_received_order.content;
      }
      if (index != 0) {
        this.notes.push({
          id: product_received_order.note_id,
          content: product_received_order.content
        });
      }
    }
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  public async submit() {
    this.isSubmit = true;
    this.submit_dialog = false;
    let result = false;
    let count_index = 0;
    this.shipment_details.forEach((shipment_detail, index: number) => {
      shipment_detail.forEach(detail => {
        const productShipment = new ProductShipment(
          this.params.product_shipments[index]
        );
        productShipment.lot_id = detail.lot_id;
        productShipment.buying_unit_price = Number(
          detail.buying_unit_price.toFixed(2)
        );
        productShipment.warehouse_id = detail.warehouse_id;
        productShipment.stock_id = detail.stock_id;
        productShipment.stockage_id = detail.stockage_id;
        productShipment.quantity = detail.quantity || 0;
        productShipment.amount = Number(detail.buying_amount.toFixed(2));
        productShipment.selling_unit_amount = Number(
          detail.selling_amount.toFixed(2)
        );
        productShipment.memo = detail.memo;
        this.params.product_shipments.push(productShipment);
      });
      count_index += 1;
    });
    this.params.product_shipments.splice(0, count_index);

    result = await this.preShipmentCreate(this.params);

    //回答納品日更新
    if (!this.received_order.fixed_delivery_date) {
      await this.adminReceivedOrderUpdateFixedDeliveryDate({
        received_order_id: this.params.received_order_id,
        fixed_delivery_date: this.fixed_delivery_date
      });
    }

    //受注商品メモ更新
    if (result) {
      result = await this.updateNote(this.notes);
    }

    if (result) {
      this.$router.go(-1);
    }
  }

  //残出荷数量計算
  public async RemainingShipments(index: number) {
    this.remaining_shipments[index] = this.received_order_quantity[index];
    // received_order_idに紐づくshipmentレコードを持ってくる
    this.shipmentList = this.shipmentFilterdSelectItem(
      this.params.received_order_id
    );
    for (let [, shipmentList] of this.shipmentList.entries()) {
      await this.getShipment(shipmentList.id);
      this.shipment.products.forEach(product => {
        if (
          this.params.product_shipments[index].product_id == product.product_id
        ) {
          this.remaining_shipments[index] -= product.quantity;
        }
      });
    }
  }

  //リアクティブ残出荷数量計算
  public NowRemainingShipments(index: number) {
    if (this.isSubmit) return;
    let remaining_shipment = this.remaining_shipments[index];
    let total_quantity = 0;
    this.shipment_details[index].forEach(shipment_detail => {
      total_quantity += Number(shipment_detail.quantity);
    });
    return remaining_shipment - total_quantity;
  }

  //メーカが選択された際の挙動
  public async makerSelected(index: number) {
    await this.getProductSegments({
      maker_id: this.maker_id[index],
      per_page: 0
    });
    this.$set(
      this.productOptions,
      index,
      this.makerFilterdSelectItem(this.maker_id[index])
    );
  }

  //商品が選択された際の挙動
  public async productSelected(index: number) {
    await this.getStockSegments({
      product_id: this.params.product_shipments[index].product_id,
      per_page: 0
    });
    this.$set(
      this.warehouseOptions,
      index,
      this.WarehouseWithProducts(
        this.params.product_shipments[index].product_id,
        this.params.customer_id,
        this.stock.id
      )
    );
    const find_product = this.findProduct(
      this.params.product_shipments[index].product_id
    );
    this.unit[index] = find_product.unit;
  }

  //倉庫が選択された際の挙動
  public warehouseSelected(index: number, index_detail: number) {
    this.isWarehouse[index][index_detail] =
      !!this.shipment_details[index][index_detail].warehouse_id;
    this.shipment_details[index][index_detail].stock_id = 0;
    this.shipment_details[index][index_detail].quantity = 0;
    this.$set(
      this.lotOptions[index],
      index_detail,
      this.warehouseFilterdSelectItem(
        this.params.product_shipments[index].product_id,
        this.params.customer_id,
        this.shipment_details[index][index_detail].warehouse_id,
        this.stock.id
      )
    );
  }

  //ロットが選択された際の挙動
  public lotSelected(index: number, index_detail: number) {
    this.shipment_details[index][index_detail].quantity = 0;
    const shipment_detail = this.shipment_details[index][index_detail];
    if (shipment_detail.stock_id) {
      const stock = this.findStock(shipment_detail.stock_id);
      shipment_detail.lot_id = stock.lot_id;
      shipment_detail.buying_unit_price = Number(
        stock.buying_unit_price.toFixed(2)
      );
      shipment_detail.stockage_id = stock.stockage_id;
      this.max_quantity[index][index_detail] = stock.quantity;
    }
    if (!shipment_detail.stock_id) {
      shipment_detail.lot_id = 0;
      shipment_detail.buying_unit_price = 0;
      shipment_detail.stockage_id = 0;
      this.max_quantity[index][index_detail] = 0;
    }
  }

  //仕入単価合計金額計算
  public calcBuyingAmount(index: number, index_detail: number) {
    const shipment_detail = this.shipment_details[index][index_detail];
    shipment_detail.buying_amount = Number(
      (
        shipment_detail.buying_unit_price * (shipment_detail.quantity || 0)
      ).toFixed(2)
    );
  }

  //売単価合計金額計算
  public calcSellingAmount(index: number, index_detail: number) {
    const shipment_detail = this.shipment_details[index][index_detail];
    shipment_detail.selling_amount = Number(
      (
        this.params.product_shipments[index].selling_unit_price *
        (shipment_detail.quantity || 0)
      ).toFixed(2)
    );
  }

  //出荷商品追加
  public appendProductShipment() {
    this.params.product_shipments.push(new ProductShipment());
  }

  //出荷商品削除
  public deleteProductShipment(index: number) {
    this.max_quantity.splice(index, 1);
    this.maker_id.splice(index, 1);
    this.unit.splice(index, 1);
    this.productOptions.splice(index, 1);
    this.customerProductOptions.splice(index, 1);
    this.lotOptions.splice(index, 1);
    this.warehouseOptions.splice(index, 1);
    this.isWarehouse.splice(index, 1);
    this.received_order_quantity.splice(index, 1);
    this.remaining_shipments.splice(index, 1);
    this.shipment_details.splice(index, 1);
    this.notes.splice(index, 1);
    this.params.product_shipments.splice(index, 1);
  }

  //quantityバリデーション
  public unitRule(value: number, index: number, index_detail: number) {
    if (!this.max_quantity[index][index_detail]) {
      return true;
    }
    return (
      value <= this.max_quantity[index][index_detail] ||
      "在庫以上は入力できません"
    );
  }
}
